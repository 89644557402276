import { Button, Nav, NavItem, Collapse } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import {
  CLIENT_API,
  DOWNLOAD_ISSUE_MESSAGE_API,
  MESSAGE_API,
  NOTIFICATION_API,
  OTHER_ISSUE_MESSAGE_API,
  PAYMENT_ISSUE_MESSAGE_API,
  USER_API,
} from "../constants/api";
import { UpdateNotification } from "../App";

const navigation = [
  {
    title: "Dashboard",
    href: "/auth/dashboard",
    icon: "bi bi-speedometer2",
  },
  {
    title: "Media Issues",
    href: "/auth/download-support-mailbox",
    icon: "bi bi-chat-dots",
  },
  {
    title: "Payment Issues",
    href: "/auth/payment-support-mailbox",
    icon: "bi bi-chat-dots",
  },
  {
    title: "Other Issues",
    href: "/auth/other-support-mailbox",
    icon: "bi bi-chat-dots",
  },

  // {
  //   title: "CRM",
  //   icon: "bi bi-funnel",
  //   subItems: [
  //     { title: "Team", href: "/auth/team" },
  //     { title: "Workplace", href: "/auth/workplace" },
  //   ],
  // },
  {
    title: "Users",
    icon: "bi bi-people",
    subItems: [
      { title: "All Users", href: "/auth/all-users" },
      { title: "Add New User", href: "/auth/add-new-user" },
    ],
  },
  {
    title: "Departments",
    href: "/auth/department",
    icon: "bi bi-building",
  },

  // {
  //   title: "Portfolios",
  //   icon: "bi bi-briefcase",
  //   subItems: [
  //     { title: "All Portfolios", href: "/auth/all-portfolios" },
  //     { title: "Add New Portfolio", href: "/auth/add-new-portfolio" },
  //   ],
  // },
  // {
  //   title: "Services",
  //   icon: "bi bi-rocket",
  //   subItems: [
  //     { title: "All Services", href: "/auth/all-services" },
  //     { title: "Add New Service", href: "/auth/add-new-service" },
  //   ],
  // },
  {
    title: "Configure",
    icon: "bi bi-gear-fill",
    subItems: [
      { title: "Package Listing", href: "/auth/allPackages" },
      { title: "Package Add", href: "/auth/addNewPackage" },
      { title: "Service Listing", href: "/auth/allServices" },
      { title: "Service Add", href: "/auth/addNewService" },
      { title: "Categories", href: "/auth/category" },
    ],
  },
  {
    title: "All Orders",
    href: "/auth/orders",
    icon: "bi bi-cart-check",
  },
  {
    title: "Settings",
    icon: "bi bi-gear",
    subItems: [
      // { title: "Site Setting", href: "/auth/settings" },
      { title: "Brands Setting", href: "/auth/settings/brand" },
      { title: "FAQ Setting", href: "/auth/settings/faqs" },
    ],
  },
];

const AdminSidebar = () => {
  const myId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [myEmail, setMyEmail] = useState("");
  const navigate = useNavigate();
  const [checkActive, upDateActive] = useState("dark");
  const location = useLocation();
  const [inboxCount, SetInboxCount] = useState(0);
  const [paymentCount, SetPaymentCount] = useState(0);
  const [otherCount, SetOtherCount] = useState(0);
  const [checkUserDropdown, setUserDropdown] = useState("close");
  const [checkCrmDropdown, setCrmDropdown] = useState("close");
  const [checkPortfolioDropdown, setPortfolioDropdown] = useState("close");
  const [checkServiceDropdown, setServiceDropdown] = useState("close");
  const [checkSettingDropdown, setSettingDropdown] = useState("close");
  const [notificationCounter, setNotificationCounter] = useState("0");
  const [noti, setNoti] = useState(null);
  const { update, setUpdate } = useContext(UpdateNotification);

  const [collapse, setCollapse] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin");
    }
  }, [navigate]);

  useEffect(() => {
    const getNotification = async () => {
      const response = await fetch(NOTIFICATION_API + "/" + myId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        setNoti(json.data);
        setNotificationCounter(json.unreadCount);
      }
    };

    getNotification();
  }, [update]);

  useEffect(() => {
    const getAdminChats = async () => {
      try{
        const response = await fetch(DOWNLOAD_ISSUE_MESSAGE_API + "/count");
        const admins = await response.json();
        if (response.ok) {
          SetInboxCount(admins.data.length);
        }
      }catch(error){
        console.error("error fetching new message count", error)
      }
   
    };

    const getPaymentChats = async () => {
      try{
        const response = await fetch(PAYMENT_ISSUE_MESSAGE_API + "/count");
        const admins = await response.json();
        if (response.ok) {
          SetPaymentCount(admins.data.length);
        }
      }catch(error){
        console.error("error fetching new message count", error)
      }
   
    };
    const getOtherChats = async () => {
      try{
        const response = await fetch(OTHER_ISSUE_MESSAGE_API + "/count");
        const admins = await response.json();
        if (response.ok) {
          SetOtherCount(admins.data.length);
        }
      }catch(error){
        console.error("error fetching new message count", error)
      }
    
    };

    const fetchChats = () => {
      getAdminChats();
      getPaymentChats();
      getOtherChats();
    }
 

    fetchChats()
    const interval = setInterval(fetchChats, 15000);

    return () => clearInterval(interval);

  }, []);

  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const toggleCollapse = (index) => {
    setCollapse((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Filter navigation items based on role
  const filteredNavigation =
    role === "super-admin" || role === "admin"
      ? navigation.filter((item) => item.title !== "CRM")
      : navigation.filter((item) =>
          ["Dashboard", "Inbox", "CRM", "All Orders"].includes(item.title)
        );

  return (
    <div className="bg-dark z-3">
      <div className="d-flex">
        <Button
          color="white"
          className="ms-auto text-white d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-x"></i>
        </Button>
      </div>
      <div className="p-3 mt-2">
        <Nav vertical className="sidebarNav">
          {filteredNavigation.map((navi, index) => (
            <NavItem key={index} className="sidenav-bg">
              {navi.subItems ? (
                <>
                  <Button
                    className="nav-link py-3 w-100 text-start"
                    color="link"
                    onClick={() => toggleCollapse(index)}
                  >
                    <i className={navi.icon}></i>
                    <span className="ms-3 d-inline-block">{navi.title}</span>
                    <span className="ml-auto float-right">
                      {collapse[index] ? (
                        <i class="bi bi-chevron-up"></i>
                      ) : (
                        <i class="bi bi-chevron-down"></i>
                      )}
                    </span>
                  </Button>
                  <Collapse isOpen={collapse[index]}>
                    <Nav vertical className="ms-3">
                      {navi.subItems.map((subItem, subIndex) => (
                        <NavItem key={subIndex} className="sidenav-bg">
                          <Link
                            to={subItem.href}
                            className={
                              location.pathname === subItem.href
                                ? "active nav-link py-2"
                                : "nav-link py-2"
                            }
                          >
                            {subItem.title}
                          </Link>
                        </NavItem>
                      ))}
                    </Nav>
                  </Collapse>
                </>
              ) : (
                <Link
                  to={navi.href}
                  className={
                    location.pathname === navi.href
                      ? "active nav-link py-3"
                      : "nav-link py-3"
                  }
                >
                  <i className={navi.icon}></i>
                  <span className="ms-3 d-inline-block">{navi.title}</span>
                  {navi.title === "Media Issues" && inboxCount > 0 ? (
                    <>
                      <span className="badge ms-2 text-bg-warning">
                        {inboxCount}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {navi.title === "Payment Issues" && paymentCount > 0 ? (
                    <>
                      <span className="badge ms-2 text-bg-warning">
                        {paymentCount}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {navi.title === "Other Issues" && otherCount > 0 ? (
                    <>
                      <span className="badge ms-2 text-bg-warning">
                        {otherCount}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </Link>
              )}
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default AdminSidebar;
