import { Button, Nav, NavItem, Collapse } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { BRIEF_OF_USER_API, CLIENT_API, CONFIGURE_API, MESSAGE_API } from "../constants/api";

import './Sidebar.css'
import { UpdateNotification } from "../App";

const Sidebar = () => {
  const myId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const [inboxCount, SetInboxCount] = useState(0);
  const location = useLocation();
  const [collapse, setCollapse] = useState({});
  const [completedBriefs, setCompletedBriefs] = useState([]);
  const [openBriefs, setOpenBriefs] = useState([]);
  const {update, setUpdate} = useContext(UpdateNotification)
  const [navigation, setNavigation] = useState([
    {
      title: "Dashboard",
      href: "/client-auth/dashboard",
      icon: "bi bi-speedometer2",
    },
    {
      title: "Add Brief",
      href: "/client-auth/add-brief",
      icon: "bi bi-card-text",
    },
    {
      title: "Open Briefs",
      icon: "bi bi-layers-fill",
      subItems: [], // To be filled with open briefs
    },
    {
      title: "Completed Briefs",
      icon: "bi bi-check-square-fill",
      subItems: [], // To be filled with completed briefs
    },
  ]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const getBriefData = async () => {
      const response = await fetch(BRIEF_OF_USER_API + "/myList/" + myId);
      const json = await response.json();
      if (response.ok) {
        setOpenBriefs(json.data.briefsInProgress);
        setCompletedBriefs(json.data.completedBriefs);
      }
    };

    const getClientChats = async () => {
      const response = await fetch(MESSAGE_API + "/client/" + myId);
      const clients = await response.json();
      if (response.ok) {
        SetInboxCount(clients.data.length);
      }
    };

    getBriefData();
    getClientChats();
  }, [myId, update]);

  // Update navigation when openBriefs or completedBriefs changes
  useEffect(() => {
    const fetchBriefTitles = async () => {
      try {
        // Fetch names for open briefs
        const openBriefsWithNames = await Promise.all(
          openBriefs.map(async (brief) => {
            const response = await fetch(`${CONFIGURE_API}/includes/singleInclude/${brief.title}`);
            const result = await response.json();
            if (response.ok && result.data.title) {
              return {
                title: result.data.title.length>16?result.data.title.slice(0, 16) + "...": result.data.title,
                href: `/client-auth/client-brief-page/${brief.clientProjectId}/${brief._id}`,
              };
            } else {
              return {
                title: brief.title.slice(0, 16),
                href: `/client-auth/client-brief-page/${brief.clientProjectId}/${brief._id}`,
              };
            }
          })
        );

        // Fetch names for completed briefs
        const completedBriefsWithNames = await Promise.all(
          completedBriefs.map(async (brief) => {
            const response = await fetch(`${CONFIGURE_API}/includes/singleInclude/${brief.title}`);
            const result = await response.json();
            if (response.ok && result.data.title) {
              return {
                title: result.data.title.length>16?result.data.title.slice(0, 16) + "...": result.data.title,
                href: `/client-auth/client-brief-page/${brief.clientProjectId}/${brief._id}`,
              };
            } else {
              return {
                title: brief.title.slice(0, 16),
                href: `/client-auth/client-brief-page/${brief.clientProjectId}/${brief._id}`,
              };
            }
          })
        );

        // Update navigation state with both open and completed briefs
        setNavigation((prevNav) =>
          prevNav.map((item) => {
            if (item.title === "Open Briefs") {
              return { ...item, subItems: openBriefsWithNames };
            } else if (item.title === "Completed Briefs") {
              return { ...item, subItems: completedBriefsWithNames };
            }
            return item;
          })
        );
      } catch (error) {
        console.error("Failed to fetch brief titles:", error);
      }
    };

    fetchBriefTitles();
  }, [openBriefs, completedBriefs, CONFIGURE_API]);

  const toggleCollapse = (index) => {
    setCollapse((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  return (
    <>
    <div className="bg-dark z-3 clients-sidebar" style={{ position: "relative", height: "100vh" }}>
      <div className="d-flex">
        <Button
          color="white"
          className="ms-auto text-white d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-x"></i>
        </Button>
      </div>
      <div className="p-3 mt-2">
        <Nav vertical className="sidebarNav">
          {navigation.map((navi, index) => (
            <NavItem key={index} className="sidenav-bg">
              {navi.subItems && navi.subItems.length > 0 ? (
                <>
                  <Button
                    className="nav-link py-3 w-100 text-start"
                    color="link"
                    onClick={() => toggleCollapse(index)}
                  >
                    <i className={navi.icon}></i>
                    <span className="ms-3 d-inline-block">{navi.title}</span>
                    <span className="ml-auto float-right">
                      {collapse[index] ? (
                        <i className="bi bi-chevron-up"></i>
                      ) : (
                        <i className="bi bi-chevron-down"></i>
                      )}
                    </span>
                  </Button>
                  <Collapse isOpen={collapse[index]}>
                    <Nav vertical className="ms-3">
                      {navi.subItems.map((subItem, subIndex) => (
                        <NavItem key={subIndex} className="sidenav-bg">
                          <Link
                            to={subItem.href}
                            className={
                              location.pathname === subItem.href
                                ? "active nav-link py-2"
                                : "nav-link py-2"
                            }
                          >
                            {subItem.title}
                          </Link>
                        </NavItem>
                      ))}
                    </Nav>
                  </Collapse>
                </>
              ) : (
                <Link
                  to={navi.href}
                  className={
                    location.pathname === navi.href
                      ? "active nav-link py-3"
                      : "nav-link py-3"
                  }
                >
                  <i className={navi.icon}></i>
                  <span className="ms-3 d-inline-block">{navi.title}</span>
                  {navi.title === "Inbox" && (
                    <span className="badge ms-2 text-bg-warning">
                      {inboxCount}
                    </span>
                  )}
                </Link>
              )}
            </NavItem>
          ))}
        </Nav>
      </div>



    </div>
       <div className="bg-dark z-3 clients-sidebar" style={{ position: "fixed", bottom: "170px", width: "260px" }}>
      {/* Settings button at the bottom */}
      <div className="position-absolute p-3 mt-2 w-100" >
      <Nav vertical className="sidebarNav">
        <NavItem className="sidenav-bg">
          <Link
            to="/client-auth/support"
            className={
              location.pathname === "/client-auth/support"
                ? "active nav-link py-3"
                : "nav-link py-3"
            }
          >
            <i className="bi bi-info-circle"></i>
            <span className="ms-3 d-inline-block">Support</span>
          </Link>
    
        </NavItem>
        <NavItem className="sidenav-bg">
  
          <Link
            to="/client-auth/setting-client"
            className={
              location.pathname === "/client-auth/setting-client"
                ? "active nav-link py-3"
                : "nav-link py-3"
            }
          >
             <i className="bi bi-gear-fill"></i>
            <span className="ms-3 d-inline-block">Settings</span>
          </Link>
        </NavItem>
        </Nav>
      </div>

       </div>
       </>
  );
};

export default Sidebar;
