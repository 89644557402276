import React, { useState, useEffect } from "react";
import "./SectionSixMobile.css";
import { CATEGORY_API, CONFIGURE_API, SERVICE_API } from "../../../constants/api";
import parse from "html-react-parser";

function SectionSixMobile() {
 
  // new code

  const [newPackages, setNewPackages] = useState(null);
  const [newCategories, setNewCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [checkActiveType, setCheckActiveType] = useState("");
  const [packagePrice, setPackagePrice] = useState("");
  const [packageServices, setPackageServices] = useState([]);
  const [packageCurrency, setPackageCurrency] = useState("");
  const [packageURL, setPackageURL] = useState("")
  const [packageId, setPackageId] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const fetchNewCategories = async () => {
      const response = await fetch(`${CATEGORY_API}/ip/list`);

      const json = await response.json();

      if (response.ok) {
        setNewCategories(json.data || []);
        setSelectedCategory(json.data[0]._id);
      }
    };

    fetchNewCategories();
  }, []);

  useEffect(() => {
    const fetchNewPackages = async () => {
      if (selectedCategory.trim() !== "") {
        const response = await fetch(
          `${CONFIGURE_API}/packages/ipBasedList/${selectedCategory}`
        );

        const json = await response.json();

        if (response.ok) {
          setNewPackages(json.data.sort((a, b) => a.price - b.price) || []);
          setCheckActiveType("Essential");
          setPackagePrice(
            json.data.find((newPackage) => newPackage.type === "Essential")
              .price
          );
          setPackageServices(
            json.data.find((newPackage) => newPackage.type === "Essential")
              .services
          );
          setPackageCurrency(
            json.data
              .find((newPackage) => newPackage.type === "Essential")
              .currency.toUpperCase()
          );
          setPackageURL(
            json.data
              .find((newPackage) => newPackage.type === "Essential")
              .stripe
          );
          setPackageId(
            json.data.find((newPackage) => newPackage.type === "Essential")._id
          );
          setDescription(
            json.data.find((newPackage) => newPackage.type === "Essential")
              .description
          );
        }
      }
    };

    fetchNewPackages();
  }, [selectedCategory]);

  useEffect(() => {
    if (checkActiveType.trim() !== "") {
      setPackagePrice(
        newPackages.find((newPackage) => newPackage.type === checkActiveType)
          .price
      );
      setPackageServices(
        newPackages.find((newPackage) => newPackage.type === checkActiveType)
          .services
      );
      setPackageCurrency(
        newPackages
          .find((newPackage) => newPackage.type === checkActiveType)
          .currency.toUpperCase()
      );
      setPackageURL(
        newPackages
          .find((newPackage) => newPackage.type === checkActiveType)
          .stripe
      );
      setPackageId(
        newPackages.find((newPackage) => newPackage.type === checkActiveType)._id
      );
      setDescription(
        newPackages.find((newPackage) => newPackage.type === checkActiveType)
          .description
      );
    }
  }, [checkActiveType]);

  const handleGetStarted = (url) => {
    localStorage.setItem("selectedPackagePrice", packagePrice);
    localStorage.setItem("selectedPackageCategory", selectedCategory);
    localStorage.setItem("selectedPackage", checkActiveType);
    localStorage.setItem("packageId", packageId)
    // window.open(url, "_blank");
    window.location.href = url;
  };
  // end new code
  return (
    <div id="section-six-mobile" className="section-six-mobile d-flex">
      <div className="container align-self-center">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <h1 className="text-center fs-1 fw-bold section-six-heading">
              Unlimited Content Plans
            </h1>
          </div>
        </div>
        <div className="row mt-4 d-lg-none d-xl-none d-flex justify-content-center">
        <div className="col-md-12 d-flex justify-content-center gap-2 flex-wrap">
        {newCategories && newCategories.length > 0 ? (
                        <>
                          {" "}
                          {newCategories.map((newCategory, index) => (
                            <React.Fragment key={newCategory._id}>
                              {index % 3 === 0 && index !== 0 && (
                                <div className="w-100"></div>
                              )}
                              <button
                                onClick={() =>
                                  setSelectedCategory(newCategory._id)
                                }
                                className={`btn btn-sm section-six-tabs ${
                                  selectedCategory === newCategory._id
                                    ? "six-active-tab"
                                    : ""
                                }`}
                              >
                                {newCategory.name}
                              </button>
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
        </div>
        <div className="row mt-4 d-flex justify-content-center">
          <div className="col-md-10">
            <div className="row">
              <div className="col-lg-5 sec-six-col-mobile p-2 d-none d-lg-block d-xl-block">
                <div className="sec-six-card-1 shadow p-4 d-flex align-items-center position-relative">
                  <img
                    className="sec-six-card-1-image-1 position-absolute top-0 end-0"
                    src="/media/lines-design.png"
                    alt=""
                  />

                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="sec-six-card-1-image"
                        src="/media/Join-HypeX.png"
                        alt=""
                      />
                    </div>
                    <div className="col-md-12">
                      <p className="text-white sec-six-card-1-heading">
                        Packages:
                      </p>
                    </div>
                    <div className="col-md-12 d-flex justify-content-start gap-2 flex-wrap">
                    {newCategories && newCategories.length > 0 ? (
                        <>
                          {" "}
                          {newCategories.map((newCategory, index) => (
                            <React.Fragment key={newCategory._id}>
                              {index % 3 === 0 && index !== 0 && (
                                <div className="w-100"></div>
                              )}
                              <button
                                onClick={() =>
                                  setSelectedCategory(newCategory._id)
                                }
                                className={`btn btn-sm section-six-tabs ${
                                  selectedCategory === newCategory._id
                                    ? "six-active-tab"
                                    : ""
                                }`}
                              >
                                {newCategory.name}
                              </button>
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 sec-six-col-mobile p-2">
                <div className="sec-six-card-2 shadow p-4 container-fluid d-flex flex-column gap-2 justify-content-center">
                  <div className="row">
                    <div className="col-12">
                      <h3 className="sec-six-card-2-heading text-center">Membership</h3>
                    </div> 
                  </div>
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <ul className="nav nav-tabs sec-six-nav-tabs">
                      {newPackages &&
                          newPackages.map((newPackage, index) => (
                            <li
                              className="nav-item sec-six-nav-item p-2"
                              role="button"
                              key={index}
                            >
                              <a
                                className={`nav-link fs-6 sec-six-nav-link ${
                                  checkActiveType === newPackage.type
                                    ? "sec-six-nav-link-active"
                                    : ""
                                }`}
                                onClick={() =>
                                  setCheckActiveType(newPackage.type)
                                }
                              >
                                {newPackage.type}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-12"
                      style={{ borderBottom: "1px solid black" }}
                    >
                      <p className="fs-3 fw-bold section-six-price text-center">
                      {packageCurrency} {packagePrice}{" "}
                        <span   style={{fontSize: "14px"}} className="fw-light">
                          
                          No commitment. Pause or cancel at any time.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p style={{fontSize: "14px"}} className="section-six-price">What's included</p>
                    </div>
                  </div>
                  <div className="row sec-six-list-mobile">
                    <div className="col-12">
                      <div style={{fontSize: "14px"}} className="row include-list row-cols-3 d-flex">
                      {packageServices.length > 0 &&
                          packageServices.map((packageService) => (
                            <div className="col-auto p-0">
                              <ul style={{listStyleType: "circle"}}>
                              <li className="include-list">{packageService}</li>
                              </ul>
                            </div>
                          ))}
                      </div>
                      {
                        description.trim() !== ""?
                        <>
                           <hr style={{ width: "100%", margin: "20px 0" }} />
                      <div className="col-12">
                      <p style={{fontSize: "14px"}} className="section-six-price">Package Description</p>
                    </div>
                      <div style={{fontSize: "14px"}} className="row include-list row-cols-3 d-flex">
                        {description.split("\n").map((line, index) => (
                          <div
                            className="col-auto p-0"
                            key={index + packageServices.length}
                          >
                            <ul style={{ listStyleType: "circle" }}>
                              <li className="include-list">{line}</li>
                            </ul>
                          </div>
                        ))}
                      </div>
                        </>
                        :
                        <></>
                      }
                   
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 d-flex flex-column">
                      <button
                        onClick={() => handleGetStarted(packageURL)}
                        className="btn section-five-cta w-50 align-self-center"
                      >
                        Get Started
                      </button>
                      <a target="_blank" href="https://calendly.com/hypeappstore/30min" className="align-self-center w-50 text-center text-dark">Book a call</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionSixMobile;
