import React, { useState, useEffect, useContext } from "react";

import { toast } from "react-toastify";
import Select from "react-select";
import {
  BRIEF_API,
  BRIEF_OF_USER_API,
  CATEGORY_API,
  CLIENT_API,
  CONFIGURE_API,
  DOWNLOAD_ISSUE_MESSAGE_API,
  OTHER_ISSUE_MESSAGE_API,
  PAYMENT_ISSUE_MESSAGE_API,
  USER_API,
} from "../../../constants/api";
import { format } from "date-fns";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Badge } from "reactstrap";
import DataTable from "react-data-table-component";
import socketIOClient from "socket.io-client";
import { FileDownload } from "@mui/icons-material";
import DownloadIssueMessages from "./DownloadIssueMessages";
import PaymentIssueMessage from "./PaymentIssueMessage";
import OtherMessages from "./OtherMessages";
import { UpdateNotification } from "../../../App";

function Support() {
  const myId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [completedBriefs, setCompletedBriefs] = useState([]);
  const [includes, setIncludes] = useState([]);


  const [question, setQuestion] = useState(0);

  const [records, setRecords] = useState([]);
  const { update, setUpdate } = useContext(UpdateNotification);
  useEffect(() => {
    const getBriefData = async () => {
      const response = await fetch(BRIEF_OF_USER_API + "/myList/" + myId);
      const json = await response.json();
      if (response.ok) {
        setCompletedBriefs(json.data.completedBriefs);
        setRecords(json.data.completedBriefs);
      }
    };
    const fetchIncludes = async () => {
      try {
        const response = await fetch(CONFIGURE_API + "/includes/list");
        const json = await response.json();

        if (response.ok) {
          if (json.data == null) {
            setRecords([]);
          } else {
            setIncludes(json.data);
          }
        } else {
          setRecords([]);
        }
      } catch (error) {
      } finally {
      }
    };

    getBriefData();

    fetchIncludes();
  }, [myId, update]);



  

  const columns = [
    {
      name: "Title",
      selector: (row) => {
        const x = includes.find((inc) => inc._id === row.title);
        return x ? x.title : "N/A";
      },
      sortable: true,
      minWidth: "100px",
      // maxWidth: "130px"
    },
    // {
    //   name: "Description",
    //   selector: (row) => row.description,
    //   sortable: true,
    //   // minWidth: "100px",
    //   maxWidth: "300px",
    // },
    // {
    //   name: "Card",
    //   selector: (row) => "**** **** 1207",
    //   sortable: true,
    // },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "150px",
    },
    {
      name: "Date Created",
      selector: (row) =>
        format(new Date(row.createdAt), "dd MMM, yyyy hh:mm a"),
      minWidth: "180px",
      maxWidth: "200px",
      grow: 2,
      sortable: true,
    },
    {
      name: "Expected Delivery",
      selector: (row) => format(new Date(row.endDate), "dd MMM, yyyy hh:mm a"),
      minWidth: "180px",
      maxWidth: "200px",
      grow: 2,
      sortable: true,
    },
    {
      name: "Last Progress",
      selector: (row) =>
        format(new Date(row.updatedAt), "dd MMM, yyyy hh:mm a"),
      minWidth: "180px",
      maxWidth: "200px",
      grow: 2,
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => (
        <Link
          to={`/client-auth/client-brief-page/${row.clientProjectId}/${row._id}`}
          className="btn btn-sm"
          style={{ color: "white", backgroundColor: "black" }}
        >
          View
        </Link>
      ),
      width: "120px",
    },
  ];

  const changeQuestion = async (number) =>{

    switch(number){
      case 2:
        const message1 = "I can't download media"
        const formData1 = new FormData();

        formData1.append("senderId", myId);
        formData1.append("clientId", myId);
        formData1.append("message", message1);
        formData1.append("role", "client");
        try {
          const response = await fetch(DOWNLOAD_ISSUE_MESSAGE_API + "/send", {
            method: "POST",
            body: formData1,
          });
          if (response.ok) {
           setQuestion(2)
          }
        } catch (error) {
        }
        break;
      case 3:
        const message2 = "I have payment related issue"
        const formData2 = new FormData();

        formData2.append("senderId", myId);
        formData2.append("clientId", myId);
        formData2.append("message", message2);
        formData2.append("role", "client");
        try {
          const response = await fetch(PAYMENT_ISSUE_MESSAGE_API + "/send", {
            method: "POST",
            body: formData2,
          });
          if (response.ok) {
           setQuestion(3)
          }
        } catch (error) {
        }
        break;
      case 4:
        const message3 = "My issue is not mentioned here"
        const formData3 = new FormData();

        formData3.append("senderId", myId);
        formData3.append("clientId", myId);
        formData3.append("message", message3);
        formData3.append("role", "client");
        try {
          const response = await fetch(OTHER_ISSUE_MESSAGE_API + "/send", {
            method: "POST",
            body: formData3,
          });
          if (response.ok) {
           setQuestion(4)
          }
        } catch (error) {
        }
        break;

    }

  }

  return (
    <div className="container-fluid">
      <h2 className="mb-3">Support</h2>

      <div className="header-body">
        {question === 0 ? (
          <div
            className="card"
            style={{ backgroundColor: "#F2F7F8", boxShadow: "none" }}
          >
            <div className="card-body d-flex justify-content-center align-items-center">
              <div className="row w-100">
                <div className="col-12 text-center">
                  <img src="/media/hand.svg" className="img-fluid" alt="" />
                </div>
                <div className="col-12 text-center">
                  <span style={{ fontSize: "24px" }}>Need a hand?</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {question === 1 ? (
          <>
            <div className="chat-client">
              <div className="chat-history-client">
                <ul>
                  <li className="clearfix-client text-end">
                    <div className="message-data-client">
                      <span className="message-data-client-time">
                        Me &nbsp;
                        <img
                          src="/media/user.png"
                          className="img-fluid"
                          alt=""
                        />
                      </span>
                    </div>
                    <div className="message-client other-message-client" style={{backgroundColor: "#DEFFDE"}}>
                      I want to open my previous brief
                    </div>
                    {/* <div className="message-data-client">
                      <span className="message-data-client-time">
                        12 10 2014
         
                      </span>
                    </div> */}
                  </li>

                  <li className="clearfix-client">
                    <div className="message-data-client">
                      <img src="/media/user.png" className="img-fluid" alt="" />{" "}
                      &nbsp; Hype X
                    </div>
                    <div className="message-client my-message-client" style={{backgroundColor: "#DEF0FF"}}>
                      Sure, here are all your previous briefs
                    </div>
                    {/* <div className="message-data-client">
                                  <span className="message-data-client-time">
                                    12/ 10/ 2024
                                  </span>
                                </div> */}
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="card"
              style={{
                backgroundColor: "#F2F7F8",

                overflowY: "scroll",
                boxShadow: "none",
              }}
            >
              <div className="card-body">
                <DataTable
                  columns={columns}
                  data={records}
                  progressComponent={
                    <div className="text-center my-2 bg-white">
                      <div
                        className="spinner-border text-secondary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  }
                  // Add custom styles or classes here using Bootstrap classes
                  className="table table-striped table-hover"
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {question === 2 ? (
          <>
         
            <DownloadIssueMessages/>
          </>
        ) : (
          <></>
        )}
        {question === 3 ? (
          <>
            <PaymentIssueMessage/>
          </>
        ) : (
          <></>
        )}
        {question === 4 ? (
          <>
            <OtherMessages/>
          </>
        ) : (
          <></>
        )}

        <div
          className="card"
          style={{ backgroundColor: "#F2F7F8", boxShadow: "none" }}
        >
          <div className="card-body">
            <div className="row w-100 gap-2 d-flex justify-content-center">
              <div className="col-md-auto col-sm-12" role="button">
                <span onClick={() => setQuestion(1)}>
                  {" "}
                  <u> Want to open a previous brief? </u>
                </span>
              </div>
              <div className="col-md-auto col-sm-12" role="button">
                <span onClick={() => changeQuestion(2)}>
                  {" "}
                  <u> Can't download files? </u>
                </span>
              </div>
              <div className="col-md-auto col-sm-12" role="button">
                <Link
                  to="/client-auth/setting-client"
                  style={{ textDecoration: "none", color: "#1E2A35" }}
                >
                  {" "}
                  <u> Change Credentials </u>
                </Link>
              </div>
              <div className="col-md-auto col-sm-12" role="button">
                <span onClick={() => changeQuestion(3)}>
                  {" "}
                  <u> Payment Issues </u>
                </span>
              </div>
              <div className="col-md-auto col-sm-12" role="button">
                <span onClick={() => changeQuestion(4)}>
                  {" "}
                  <u> Other </u>
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Support;
