
export const USER_API = "https://api.hype-x.ca/api/auth"
export const SERVICE_API = "https://api.hype-x.ca/api/service"
export const SUB_SERVICE_API = "https://api.hype-x.ca/api/sub-service"
export const MEDIA_API = "https://api.hype-x.ca/api/media"
export const SETTING_API = "https://api.hype-x.ca/api/setting"
export const CLIENT_API = "https://api.hype-x.ca/api/client/project"
export const DEPARTMENT_API = "https://api.hype-x.ca/api/department" 
export const CATEGORY_API = "https://api.hype-x.ca/api/category" 
export const MESSAGE_API = "https://api.hype-x.ca/api/message"
export const CLIENT_PROJECT_MESSAGE_API = "https://api.hype-x.ca/api/clientProjectMessage"
export const CLIENT_BRIEF_MESSAGE_API = "https://api.hype-x.ca/api/clientBriefMessage"
export const PORTFOLIO_API = "https://api.hype-x.ca/api/portfolio"
export const BRAND_API = "https://api.hype-x.ca/api/brand"
export const FAQ_API = "https://api.hype-x.ca/api/faq"
export const COUNTRY_API = "https://api.hype-x.ca/api/country"
export const CONFIGURE_API = "https://api.hype-x.ca/api/configure"

export const STRIPE_API = "https://api.hype-x.ca/api/stripe"

export const NOTIFICATION_API = "https://api.hype-x.ca/api/notification"  
export const TEAM_API = "https://api.hype-x.ca/api/team"
export const TASK_API = "https://api.hype-x.ca/api/task"
export const TRACKING_ID = "G-345RGT68K6"
export const PROPERTY_ID = "448284039"
export const CLIENT_ID = "700163561306-aam2hnm185u1cqur9h4di7hjksjjjmal.apps.googleusercontent.com"
export const CLIENT_SECRET = "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"
export const GA_TOKEN = "ya29.a0AXooCguDay31BFzT9V37RXXKf0v3BylWTh7oiMra183SGH5GpXZKaLMgNdthyVX7c6wNr2bXYW4uUWDNt1-r9We71CcONm8XjF01mSRlgsdklL4qhVoOnFMT-Rcs4l_4_iFPKs5zgJG7dyMBr-KhpBCh8nifUYcILdwaCgYKAegSARASFQHGX2MiiK6pKdOfGBZ2MIBsd5zJ5A0170"
export const BRIEF_API = "https://api.hype-x.ca/api/brief/"
export const BRIEF_OF_USER_API = "https://api.hype-x.ca/api/briefOfUser/"
export const DOWNLOAD_ISSUE_MESSAGE_API = "https://api.hype-x.ca/api/clientDownloadIssueMessage"
export const OTHER_ISSUE_MESSAGE_API = "https://api.hype-x.ca/api/clientOtherIssueMessage"
export const PAYMENT_ISSUE_MESSAGE_API = "https://api.hype-x.ca/api/clientPaymentIssueMessage"


// export const USER_API = "http://localhost:5000/api/auth"
// export const SERVICE_API = "https://localhost:5000/service"
// export const SUB_SERVICE_API = "http://localhost:5000/api/sub-service"
// export const MEDIA_API = "http://localhost:5000/api/media"
// export const SETTING_API = "http://localhost:5000/api/setting"
// export const CLIENT_API = "http://localhost:5000/api/client/project"
// export const DEPARTMENT_API = "http://localhost:5000/api/department" 
// export const CATEGORY_API = "http://localhost:5000/api/category" 
// export const MESSAGE_API = "http://localhost:5000/api/message"
// export const CLIENT_PROJECT_MESSAGE_API = "http://localhost:5000/api/clientProjectMessage"
// export const CLIENT_BRIEF_MESSAGE_API = "http://localhost:5000/api/clientBriefMessage"
// export const PORTFOLIO_API = "http://localhost:5000/api/portfolio"
// export const BRAND_API = "http://localhost:5000/api/brand"
// export const FAQ_API = "http://localhost:5000/api/faq"
// export const COUNTRY_API = "http://localhost:5000/api/country"
// export const CONFIGURE_API = "http://localhost:5000/api/configure"

// export const STRIPE_API = "http://localhost:5000/api/stripe"

// export const NOTIFICATION_API = "http://localhost:5000/api/notification"  
// export const TEAM_API = "http://localhost:5000/api/team"
// export const TASK_API = "http://localhost:5000/api/task"
// export const TRACKING_ID = "G-345RGT68K6"
// export const PROPERTY_ID = "448284039"
// export const CLIENT_ID = "700163561306-aam2hnm185u1cqur9h4di7hjksjjjmal.apps.googleusercontent.com"
// export const CLIENT_SECRET = "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"
// export const GA_TOKEN = "ya29.a0AXooCguDay31BFzT9V37RXXKf0v3BylWTh7oiMra183SGH5GpXZKaLMgNdthyVX7c6wNr2bXYW4uUWDNt1-r9We71CcONm8XjF01mSRlgsdklL4qhVoOnFMT-Rcs4l_4_iFPKs5zgJG7dyMBr-KhpBCh8nifUYcILdwaCgYKAegSARASFQHGX2MiiK6pKdOfGBZ2MIBsd5zJ5A0170"
// export const BRIEF_API = "http://localhost:5000/api/brief/"
// export const BRIEF_OF_USER_API = "http://localhost:5000/api/briefOfUser/"
// export const DOWNLOAD_ISSUE_MESSAGE_API = "http://localhost:5000/api/clientDownloadIssueMessage"
// export const OTHER_ISSUE_MESSAGE_API = "http://localhost:5000/api/clientOtherIssueMessage"
// export const PAYMENT_ISSUE_MESSAGE_API = "http://localhost:5000/api/clientPaymentIssueMessage"
