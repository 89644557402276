import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import "./DownloadMailbox.css";
import { format } from "date-fns";
import socketIOClient from "socket.io-client";
import {
  CLIENT_API,
  DEPARTMENT_API,
  DOWNLOAD_ISSUE_MESSAGE_API,
  MESSAGE_API,
  USER_API,
} from "../../constants/api";
import Picker from "emoji-picker-react";
// import { NotificationData } from "../../../components/privateroutes/ClientPrivateRoutes";

function DownloadMailbox() {
  const [projects, setProjects] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [chatOpen, setChatOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  // const { notification, setNotification } = useContext(NotificationData);
  const [socket, setSocket] = useState(null);
  const [clients, setClients] = useState(null);
  const [records, setRecords] = useState([]);
  const [chat, setChat] = useState(null);
  const [selectedChatName, setSelectedChatName] = useState("");
  const myId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [checkActiveChat, setActiveChat] = useState("");
  const scrollableDivRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileRef = useRef("");
  // var progress_initial = 0;
  const handleAttachFile = () => {
    fileRef.current.click();
  };
  // const runProgress = () => {
  //   if (progress_initial == 0) {
  //     progress_initial = 1;
  //     var elem = document.getElementById("myBar");
  //     var width = 1;
  //     var id = setInterval(frame, 10);
  //     function frame() {
  //       if (width >= 100) {
  //         clearInterval(id);
  //         progress_initial = 0;
  //       } else {
  //         width++;
  //         elem.style.width = width + "%";

  //       }

  //     }
  //   }
  //   document.getElementById("myBar").style.width = 0 + "%";
  // };
  const handleImageChange = async (e) => {
    setFile(e.target.files[0]);
    setMessage(e.target.files[0].name);
    document.getElementById("client-message-input").disabled = true;
  };

  const onEmojiClick = (e) => {
    console.log(e);
    setMessage((prev) => prev + e.emoji);
    setShowPicker(false);
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");

    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setIsMobile(true);
      }
    };

    // Initial check
    if (mediaQuery.matches) {
      setIsMobile(true);
    }

    // Add listener
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // Cleanup listener on component unmount
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, [chatOpen]);

  // Socket.io
  useEffect(() => {
    const socket = socketIOClient("https://api.hype-x.ca");
    setSocket(socket);
    socket.emit("storeSocketId", myId);
    socket.on("receive_download_issue_message", (message) => {
      setChat((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const [downloadCount, SetDownloadCount] = useState([]);

  useEffect(() => {


    const getDownloadChats = async () => {
      try{
        const response = await fetch(DOWNLOAD_ISSUE_MESSAGE_API + "/count");
        const admins = await response.json();
        if (response.ok) {
          SetDownloadCount(admins.data || []);
        }
      }catch(error){
        console.error("error fetching new message count", error)
      }
   
    };


    const fetchChats = () => {
     
      getDownloadChats();
  
    }
 

    fetchChats()
    const interval = setInterval(fetchChats, 15000);

    return () => clearInterval(interval);

  }, []);

  useEffect(() => {
    const getClientChats = async () => {
      try {
        const response = await fetch(DOWNLOAD_ISSUE_MESSAGE_API + "/clients");
        const clients = await response.json();
        console.log(clients.data);
        if (response.ok) {
          setClients(clients.data.sort((a, b) => b.createdAt - a.createdAt));
          setRecords(clients.data.sort((a, b) => b.createdAt - a.createdAt));
          console.log(clients.data.length);
        }
      } catch (error) {
        console.log(error);
      }

   
    };

    getClientChats();
  }, []);

  const clearNotification = async (clientId) => {
    try {
      const response = await fetch(
        DOWNLOAD_ISSUE_MESSAGE_API + "/clear/" + clientId,
        {
          method: "DELETE",
        }
      );
      const json = await response.json();
      if (response.ok) {
        console.log(json.message);
      }
    } catch (error) {
      console.error("error clearing notifications", error);
    }
  };

  const getChat = async (c_ID, r_Name) => {
    const response = await fetch(DOWNLOAD_ISSUE_MESSAGE_API + "/" + c_ID);
    const myChat = await response.json();

    if (response.ok) {
      setChat(myChat.data);
      clearNotification(c_ID);
    }

    setChatOpen(true);
    setSelectedChatName(r_Name);

    setActiveChat(c_ID);
    // setNotification("");
  };

  useEffect(() => {
    scrollableDivRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [chat]);

  const handleSend = async () => {
    if (message.trim() !== "") {
      document.getElementById("client-message-input").placeholder =
        "sending...";
      setLoading(true);

      const formData = new FormData();

      formData.append("senderId", myId);
      formData.append("clientId", checkActiveChat);
      formData.append("role", "admin");

      if (file) {
        formData.append("file", file);
      } else {
        if (message.trim() !== "") {
          formData.append("message", message);
        }
      }

      try {
        const response = await fetch(DOWNLOAD_ISSUE_MESSAGE_API + "/send", {
          method: "POST",
          body: formData,
        });
      } catch (error) {
      } finally {
        if (file) {
          // await runProgress();
        }
        setMessage("");

        setFile("");
        getChat(checkActiveChat, selectedChatName);

        document.getElementById("client-message-input").disabled = false;
        setLoading(false);
        document.getElementById("client-message-input").placeholder =
          "Type message...";
      }
    }
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const createdAtDate = new Date(timestamp);
    const timeDifference = now - createdAtDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days === 1 ? "" : "s"} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else {
      return `${seconds} second${seconds === 1 ? "" : "s"} ago`;
    }
  };

  const setFilter = (value) => {
    const result = clients.filter((record) => {
      return record.receiverName.toLowerCase().includes(value.toLowerCase());
    });

    setRecords(result);
  };

  return (
    <>
      <div className="container-fluid">
        <h2 className="mb-5">Inbox</h2>

        <div className="header-body">
          <div className="row clearfix-client">
            {/* <div className="col-lg-12">
              <div id="myProgress">
                <div id="myBar"></div>
              </div>
            </div> */}
            <div className="col-lg-12">
              <div className="card chat-app-client">
                <div
                  id="plist"
                  className={`people-list-client ${
                    chatOpen && isMobile ? "d-none" : ""
                  }`}
                >
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="bi bi-search"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  </div>
                  <ul className="list-unstyled chat-list-client mt-2 mb-0">
                    {records &&
                      records.map((client) => (
                        <li
                          onClick={() =>
                            getChat(client._id, client.receiverName)
                          }
                          key={client._id}
                          className={`clearfix-client ${downloadCount.find(pay => pay.clientId === client._id) ? "bg-success-subtle": ""} ${
                            client._id === checkActiveChat ? "active" : ""
                          }`}
                        >
                          <img
                            src="https://bootdey.com/img/Content/avatar/avatar2.png"
                            alt="avatar"
                          />
                          <div className="about">
                            <div className="name">{client.receiverName}</div>
                            <div className="status">
                              {" "}
                              <i className={`bi bi-chat-dots-fill text-dark ${downloadCount.find(pay => pay.clientId === client._id) ? "": "d-none"}`} /> &nbsp;


                              {getTimeAgo(client.createdAt)}{" "}
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                <div
                  className={`chat-client ${
                    chatOpen === false && isMobile === true ? "d-none" : ""
                  }`}
                >
                  {selectedChatName == "" ? (
                    <></>
                  ) : (
                    <div className="chat-header-client clearfix-client">
                      <div className="row">
                        <div className="col-12">
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#view_info"
                          >
                            <img
                              src="https://bootdey.com/img/Content/avatar/avatar2.png"
                              alt="avatar"
                            />
                          </a>
                          <div className="chat-about">
                            <h6 className="m-b-0">{selectedChatName}</h6>
                            <small>Last seen: 2 hours ago</small>
                          </div>
                          {chatOpen && isMobile ? (
                            <>
                              <i
                                className="bi bi-x float-right"
                                role="button"
                                onClick={() => setChatOpen(false)}
                              ></i>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="chat-history-client">
                    <ul className="mb-0" ref={scrollableDivRef}>
                      {chat &&
                        chat.map((data) =>
                          data.senderRole === "super-admin" ||
                          data.senderRole === "admin" ? (
                            <li className="clearfix-client">
                              <div className="message-data-client text-end">
                                <span className="message-data-client-time">
                                  {format(
                                    new Date(data.createdAt),
                                    "dd MMM, yyyy hh:mm a"
                                  )}
                                </span>
                              </div>
                              <div
                                className="message-client other-message-client float-right"
                                style={{ backgroundColor: "#DEFFDE" }}
                              >
                                {data.message ? <>{data.message}</> : <></>}
                                {data.file.length !== 0 ? (
                                  <>
                                    {data.file[0].secure_url
                                      .split(".")
                                      .pop()
                                      .toLowerCase() === "png" ||
                                    data.file[0].secure_url
                                      .split(".")
                                      .pop()
                                      .toLowerCase() === "jpg" ||
                                    data.file[0].secure_url
                                      .split(".")
                                      .pop()
                                      .toLowerCase() === "jpeg" ? (
                                      <>
                                        {" "}
                                        <img
                                          className=""
                                          style={{
                                            width: "100%",
                                            height: "250px",
                                          }}
                                          src={data.file[0].secure_url}
                                          alt=""
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <i className="bi bi-file-pdf-fill text-danger" />
                                        &nbsp;
                                        {data.file[0].fileName}
                                      </>
                                    )}

                                    <br />
                                    <a
                                      target="_blank"
                                      href={data.file[0].secure_url}
                                      download={data.file[0].secure_url}
                                    >
                                      Download
                                    </a>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </li>
                          ) : (
                            <li className="clearfix-client">
                              <div className="message-data-client">
                                <span className="message-data-client-time">
                                  {format(
                                    new Date(data.createdAt),
                                    "dd MMM, yyyy hh:mm a"
                                  )}
                                </span>
                              </div>
                              <div
                                className="message-client my-message-client"
                                style={{ backgroundColor: "#DEF0FF" }}
                              >
                                {data.message ? <>{data.message}</> : <></>}
                                {data.file.length !== 0 ? (
                                  <>
                                    {data.file[0].secure_url
                                      .split(".")
                                      .pop()
                                      .toLowerCase() === "png" ||
                                    data.file[0].secure_url
                                      .split(".")
                                      .pop()
                                      .toLowerCase() === "jpg" ||
                                    data.file[0].secure_url
                                      .split(".")
                                      .pop()
                                      .toLowerCase() === "jpeg" ? (
                                      <>
                                        {" "}
                                        <img
                                          className=""
                                          style={{
                                            width: "100%",
                                            height: "250px",
                                          }}
                                          src={data.file[0].secure_url}
                                          alt=""
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <i className="bi bi-file-pdf-fill text-danger" />
                                        &nbsp;
                                        {data.file[0].fileName}
                                      </>
                                    )}

                                    <br />
                                    <a
                                      target="_blank"
                                      href={data.file[0].secure_url}
                                      download={data.file[0].secure_url}
                                    >
                                      Download
                                    </a>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </li>
                          )
                        )}
                    </ul>
                    {showPicker && (
                      <div
                        className="float-right"
                        style={{
                          zIndex: "7",
                          position: "absolute",
                          right: "10px",
                          bottom: "50px",
                        }}
                      >
                        <Picker
                          pickerStyle={{ width: "100%", opacity: "0.1" }}
                          onEmojiClick={onEmojiClick}
                        />
                      </div>
                    )}
                  </div>
                  {selectedChatName == "" ? (
                    <></>
                  ) : (
                    <div className="chat-message-client clearfix-client">
                      <div className="input-group mb-0">
                        <div role="button" className="input-group-prepend">
                          <span
                            onClick={() => setShowPicker((val) => !val)}
                            className="input-group-text"
                          >
                            <i class="bi bi-emoji-smile"></i>
                          </span>
                        </div>
                        <div role="button" className="input-group-prepend">
                          <span
                            onClick={handleAttachFile}
                            className="input-group-text"
                          >
                            <i class="bi bi-file-earmark-arrow-up"></i>
                          </span>
                          <input
                            ref={fileRef}
                            type="file"
                            onChange={handleImageChange}
                            className="form-control-file d-none"
                            id="exampleFormControlFile1"
                          />
                        </div>

                        <input
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSend();
                            }
                          }}
                          id="client-message-input"
                          type="text"
                          className="form-control"
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Enter text here..."
                          value={message}
                        />
                        {loading === true ? (
                          <></>
                        ) : (
                          <>
                            <div role="button" className="input-group-prepend">
                              <span
                                onClick={handleSend}
                                className="input-group-text"
                              >
                                <i class="bi bi-send"></i>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DownloadMailbox;
